var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c("v-col", { attrs: { cols: "2" } }, [
            _c("header", { staticClass: "app-welcome-message" }, [
              _c("h4", [_vm._v("Verbruikgegevens")]),
            ]),
          ]),
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: { dark: "", disabled: _setup.inEdit("any") },
                  on: { click: _setup.addUsage },
                },
                [
                  _c("v-icon", { attrs: { dark: "", center: "" } }, [
                    _vm._v("mdi-plus"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        _setup.ValidationObserver,
        { ref: "obsUsage" },
        [
          _c(
            "v-form",
            [
              _c("v-data-table", {
                attrs: {
                  dense: "",
                  headers: _setup.headers,
                  "hide-default-footer": "",
                  items: _setup.usages,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.year`,
                      fn: function ({ item }) {
                        return [
                          !_setup.inEdit(item.id)
                            ? _c("span", [
                                _vm._v(" " + _vm._s(item.year) + " "),
                              ])
                            : _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Jaar",
                                  rules: "required:numeric|between:1950,2050",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              "hide-details": "auto",
                                              outlined: "",
                                              "persistent-placeholder": "",
                                              dense: "",
                                              "error-messages": errors,
                                              readonly: false,
                                            },
                                            model: {
                                              value: item.year,
                                              callback: function ($$v) {
                                                _vm.$set(item, "year", $$v)
                                              },
                                              expression: "item.year",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                        ]
                      },
                    },
                    {
                      key: `item.value`,
                      fn: function ({ item }) {
                        return [
                          !_setup.inEdit(item.id)
                            ? _c("span", [
                                _vm._v(" " + _vm._s(item.value) + " "),
                              ])
                            : _c(_setup.ValidationProvider, {
                                attrs: {
                                  name: "Waarde",
                                  rules:
                                    "required:decimal:4|between:0,999999999.9999",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ errors }) {
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              "hide-details": "auto",
                                              outlined: "",
                                              "persistent-placeholder": "",
                                              dense: "",
                                              "error-messages": errors,
                                              readonly: false,
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function ($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                        ]
                      },
                    },
                    {
                      key: `item.modifiedDate`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _setup.formatDateUtcDT(item.modifiedDate)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.actions`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  disabled:
                                                    _setup.inEdit("any"),
                                                  icon: "",
                                                  small: "",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _setup.editRow(
                                                      item.id
                                                    )
                                                  },
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v(" mdi-pencil ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Bewerken")])]
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.confirm`,
                      fn: function ({ item }) {
                        return [
                          _setup.inEdit(item.id)
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_setup.inEdit(
                                                          item.id
                                                        ) || _setup.isSaving,
                                                      icon: "",
                                                      small: "",
                                                      plain: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _setup.saveRow()
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      " mdi-checkbox-marked-circle "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Opslaan")])]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: `item.cancel`,
                      fn: function ({ item }) {
                        return [
                          _setup.inEdit(item.id)
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_setup.inEdit(
                                                          item.id
                                                        ) || _setup.isSaving,
                                                      icon: "",
                                                      small: "",
                                                      plain: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _setup.cancelRow(
                                                          item.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v(" mdi-cancel ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Annuleren")])]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: `item.delete`,
                      fn: function ({ item }) {
                        return [
                          _setup.inEdit(item.id) &&
                          _setup.currentEdit.id !== "new"
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_setup.inEdit(
                                                          item.id
                                                        ) || _setup.isSaving,
                                                      icon: "",
                                                      small: "",
                                                      plain: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _setup.deleteRow()
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v(" mdi-delete ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Verwijderen")])]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(_setup.AlertOkCancel, {
        attrs: {
          title: "Verwijderen verbruiksgegevens",
          showDelete: "",
          body: _setup.modalDeleteBody,
          persistent: false,
          modalOpen: _setup.modalDeleteOpen,
        },
        on: { onDialogResult: _setup.onDeleteYearlyUsageDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }