var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        _setup.ValidationObserver,
        { ref: "obs" },
        [
          _c(
            "v-form",
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "pa-0": "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "masonry",
                            {
                              attrs: {
                                cols: { default: 2, 1264: 1 },
                                gutter: 12,
                              },
                            },
                            [
                              _c(
                                "v-container",
                                {
                                  staticClass: "boxed",
                                  class: { noBorders: false },
                                  attrs: { fluid: "" },
                                },
                                [
                                  _c("header", [_vm._v("Geldigheid")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Geldig vanaf",
                                              rules: "date_between|required",
                                              mode: _setup.checkDateValid,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(_setup.DateInput, {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        label: "Geldig vanaf",
                                                        "persistent-placeholder":
                                                          "",
                                                        errors: errors,
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.register
                                                            .validFrom,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.register,
                                                            "validFrom",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "register.validFrom",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Geldig tot",
                                              rules: `date_between|date_after:${_setup.register.validFrom}`,
                                              mode: _setup.checkDateValid,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(_setup.DateInput, {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        label: "Geldig tot",
                                                        "persistent-placeholder":
                                                          "",
                                                        errors: errors,
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.register
                                                            .validTill,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.register,
                                                            "validTill",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "register.validTill",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm.addModus
                                ? _c(
                                    "v-container",
                                    {
                                      staticClass: "boxed",
                                      class: { noBorders: false },
                                    },
                                    [
                                      _c("header", [_vm._v("Aanpassing")]),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "noBorders",
                                                attrs: {
                                                  "hide-details": "auto",
                                                  label: "Bewerker",
                                                  "persistent-placeholder": "",
                                                  readonly: true,
                                                },
                                                model: {
                                                  value:
                                                    _setup.register.modifiedBy,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _setup.register,
                                                      "modifiedBy",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "register.modifiedBy",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "noBorders",
                                                attrs: {
                                                  "hide-details": "auto",
                                                  label: "Datum",
                                                  "persistent-placeholder": "",
                                                  readonly: true,
                                                },
                                                model: {
                                                  value:
                                                    _setup.modDateFormatted,
                                                  callback: function ($$v) {
                                                    _setup.modDateFormatted =
                                                      $$v
                                                  },
                                                  expression:
                                                    "modDateFormatted",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "masonry",
                            {
                              class: { noBorders: false },
                              attrs: {
                                cols: { default: 3, 768: 2, 1264: 1 },
                                gutter: 12,
                              },
                            },
                            [
                              _c(
                                "v-container",
                                { staticClass: "boxed" },
                                [
                                  _c("header", [_vm._v("Algemene gegevens")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Omschrijving",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label: "Omschrijving",
                                                        "persistent-placeholder":
                                                          "",
                                                        "error-messages":
                                                          errors,
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.register
                                                            .description,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.register,
                                                            "description",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "register.description",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Type",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        "persistent-placeholder":
                                                          "",
                                                        label: "Type",
                                                        items: _setup.typeList,
                                                        "item-text":
                                                          "description",
                                                        "item-value": "key",
                                                        "error-messages":
                                                          errors,
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.register
                                                            .typeId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.register,
                                                            "typeId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "register.typeId",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "EnergyType",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        "persistent-placeholder":
                                                          "",
                                                        label: "Energietype",
                                                        items:
                                                          _setup.energyTypeList,
                                                        "item-text":
                                                          "description",
                                                        "item-value": "key",
                                                        "error-messages":
                                                          errors,
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.register
                                                            .energyTypeId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.register,
                                                            "energyTypeId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "register.energyTypeId",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "UnitType",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        "persistent-placeholder":
                                                          "",
                                                        label: "Eenheid",
                                                        items:
                                                          _setup.unitTypeList,
                                                        "item-text":
                                                          "description",
                                                        "item-value": "key",
                                                        "error-messages":
                                                          errors,
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.register
                                                            .unitTypeId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.register,
                                                            "unitTypeId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "register.unitTypeId",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-container",
                                { staticClass: "boxed" },
                                [
                                  _c("header", [_vm._v("Bron")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-switch", {
                                            class: {
                                              noBorders: !_setup.allowEdit,
                                            },
                                            attrs: {
                                              "hide-details": "auto",
                                              label: "Indicatief",
                                              "persistent-placeholder": "",
                                              outlined: _setup.allowEdit,
                                              readonly: !_setup.allowEdit,
                                            },
                                            model: {
                                              value:
                                                _setup.register.isIndicative,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _setup.register,
                                                  "isIndicative",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "register.isIndicative",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Bron",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label: "Bron",
                                                        items:
                                                          _setup.registerSourcesList,
                                                        "item-text":
                                                          "description",
                                                        "item-value": "key",
                                                        "persistent-placeholder":
                                                          "",
                                                        "error-messages":
                                                          errors,
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.register
                                                            .source,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.register,
                                                            "source",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "register.source",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Bronreferentie",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label: "Bronreferentie",
                                                        "persistent-placeholder":
                                                          "",
                                                        "error-messages":
                                                          errors,
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.register
                                                            .sourceReference,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.register,
                                                            "sourceReference",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "register.sourceReference",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-container",
                                { staticClass: "boxed" },
                                [
                                  _c("header", [_vm._v("Notities")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Notities",
                                              rules: "max:1000",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        "persistent-placeholder":
                                                          "",
                                                        counter: 1000,
                                                        "error-messages":
                                                          errors,
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.register.note,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.register,
                                                            "note",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "register.note",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "mt-5": "" } },
                    [
                      !_setup.allowEdit
                        ? _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { disabled: _setup.disableSave },
                                  on: {
                                    click: function ($event) {
                                      return _setup.onEdit()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-pencil")]
                                  ),
                                  _vm._v(" Wijzigen "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-col",
                            {
                              staticClass: "form-group",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary",
                                  attrs: { disabled: _setup.disableSave },
                                  on: {
                                    click: function ($event) {
                                      return _setup.onSave()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", left: "" } },
                                    [_vm._v("mdi-checkbox-marked-circle")]
                                  ),
                                  _vm._v(" Opslaan "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _setup.allowEdit,
                              expression: "allowEdit",
                            },
                          ],
                          staticClass: "form-group",
                          attrs: { cols: "auto" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _setup.onCancel()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-cancel"),
                              ]),
                              _vm._v(" Annuleren "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.addModus
        ? _c(_setup.RegisterUsageYearlyDetails, {
            attrs: {
              registerId: _setup.register.id,
              unitType: _setup.unitTypeDescription,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }